import React, {useCallback, useEffect, useMemo} from "react";
import {EditorBackground} from "./editor-background";
import {EditorForeground} from "./editor-foreground";
import {useFileContext} from "./file-context";
import {FREEHAND, TEXT, useFreehand, usePolygon, useText} from "./tools";
import {Controller, useControllerCallbacks, useEnabledController} from "./tools/controllers/controller";
import {path, svg, text} from "@markham/svg-control";
import useWindowSize from "react-use-window-size";
import {useTransformContext} from "./context";
import {MOVE, useToolColorContext} from "./tools/context";

export function EditorSurface() {

  const { matchSizeStyle, width, height, foregroundSVGCore, updateForeground } = useFileContext();

  const { setScale } = useTransformContext();

  useEffect(() => {
    setScale(1.001);
  }, [setScale]);

  const [color] = useToolColorContext();

  const onComplete = useCallback((controller: Controller) => {
    if (!foregroundSVGCore) {
      return;
    }

    const $ = foregroundSVGCore.getCheerio();
    const elementString = makeElementForControllerPoints(controller, color);
    $(`svg [data-id="${controller.id}"]`).remove();
    $("svg").append(elementString);
    updateForeground(foregroundSVGCore.getSVG());
  }, [foregroundSVGCore, updateForeground, color]);

  const freehand = useFreehand({ onComplete });
  const polygon = usePolygon({ onComplete, filled: false });
  const polygonFilled = usePolygon({ onComplete, filled: true });
  const text = useText({ onComplete });
  const controller = useEnabledController(freehand, polygon, polygonFilled, text);
  const callbacks = useControllerCallbacks(controller);

  // Lock scroll if the controller is enabled
  // useEffect(() => {
  //   setScrollLocked(!!controller);
  // }, [controller, setScrollLocked]);

  const editing = useMemo((): string | undefined => {
    if (!controller) {
      return undefined;
    }
    const { points } = controller;

    if (points.length === 0) {
      return undefined;
    }

    const $ = foregroundSVGCore.getCheerio();
    const foundInForeground =  $(`svg [data-id="${controller.id}"]`);

    if (foundInForeground.length) {
      foundInForeground.remove();
      updateForeground(foregroundSVGCore.getSVG());
    }

    const core = svg(width, height);
    const elementString = makeElementForControllerPoints(controller, color);
    if (elementString) {
      const $ = core.getCheerio();
      $("svg").append(elementString);
    }
    const svgString = core.getSVG();
    return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;
  }, [controller, width, height, foregroundSVGCore, updateForeground, color]);

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const containerStyles = useMemo(() => {
    if (windowWidth >= windowHeight) {
      return {
        width: "100vw",
        height: (windowWidth / width) * windowHeight
      };
    } else {
      return {
        height: "100vh",
        width: (windowHeight / height) * windowWidth
      };
    }
  }, [windowWidth, windowHeight, width, height]);

  const scaleStyles = useMemo(() => {
    if (windowWidth >= windowHeight) {
      return {
        transformOrigin: "0 0",
        transform: `scale(${windowWidth / width})`
      };
    } else {
      return {
        transformOrigin: "0 0",
        transform: `scale(${windowHeight / height})`
      };
    }
  }, [windowWidth, windowHeight, width, height]);

  return (
    <div
      className="editor-surface"
      {...callbacks}
      style={containerStyles}
    >
      <div style={scaleStyles}>
        <EditorBackground />
        <EditorForeground />
        <div style={matchSizeStyle} className="editor-foreground-transient">
          {
            editing ? (
              <img src={editing} alt="Transient Editing Surface" style={matchSizeStyle} />
            ) : undefined
          }
        </div>
      </div>
    </div>
  );
}

function makeElementForControllerPoints(controller: Controller, color: string): string {
  const { tool } = controller;

  if (tool === MOVE) {
    return "";
  } else if (tool === TEXT) {
    return makeText();
  } else {
    return makePath();
  }

  function makeText(): string {
    const { points, points: { [points.length - 1]: position }, content } = controller;

    if (!content) {
      return "";
    }

    const currentText = text(content || "", position);

    currentText.setAttribute("data-id", controller.id);
    currentText.setAttribute("data-created-at", Date.now().toString());
    currentText.setAttribute("fill", color);
    currentText.setAttribute("font-family", "sans-serif");

    return currentText.toString();
  }

  function makePath(): string {
    const { points } = controller;

    const currentPath = path();

    currentPath.setAttribute("data-id", controller.id);
    currentPath.setAttribute("data-created-at", Date.now().toString());

    if (points.length === 1 && tool === FREEHAND) {
      return "";
    }

    if (points.length === 1) {

      const [x, y] = points[0];
      currentPath.setAttribute("stroke", "red");
      currentPath.addPoint(x-1, y-1);
      currentPath.addPoint(x+1, y-1);
      currentPath.addPoint(x+1, y+1);
      currentPath.addPoint(x-1, y+1);
      currentPath.close();

    } else {
      currentPath.setAttribute("stroke", color);

      points.forEach(([x, y]) => currentPath.addPoint(x, y));

      if (controller.closed) {
        currentPath.close();
      }

      if (controller.filled) {
        currentPath.setAttribute( "fill", color);
        currentPath.setAttribute( "fill-opacity", "0.15");
      } else {
        currentPath.setAttribute( "fill", "transparent");
      }
    }

    return currentPath.toString();
  }
}
