import {useToolColorContext} from "../context";
import React, { useCallback, useState} from "react";
import {CirclePicker, ColorResult} from "react-color";
import Modal from "react-modal";

export function useChangeColor(): [boolean, () => void, () => void] {
  const [changeColor, setChangeColor] = useState<boolean>(false);
  const onChangeColor = useCallback(() => {
    setChangeColor(true);
  }, [setChangeColor]);
  const onCompleteChangeColor = useCallback(() => {
    setChangeColor(false);
  }, [setChangeColor]);
  return [changeColor, onChangeColor, onCompleteChangeColor];
}

export interface ColorPickerProps {
  enabled: boolean;
  onComplete(): void;
}

const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

export function ColorPicker({ enabled, onComplete }: ColorPickerProps) {
  const [color, setColor] = useToolColorContext();
  const onChange = useCallback((color: ColorResult) => {
    setColor(color.hex);
  }, [setColor]);
  const onChangeComplete = useCallback((color: ColorResult) => {
    setColor(color.hex);
    onComplete();
  }, [setColor, onComplete]);
  return (
    <Modal
      isOpen={enabled}
      onRequestClose={onComplete}
      style={modalStyles}
    >
      <CirclePicker
        color={color}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
      />
    </Modal>
  );
}
