import {useCallback, PointerEvent, useMemo, useRef} from "react";
import { ControllerOptions, Controller} from "./controller";
import { useDefaultController } from "./default";
import {Point} from "@markham/svg-control";
import {useTransformContext} from "../../context";

export const POLYGON_FILLED = "POLYGON_FILLED";
export const POLYGON = "POLYGON";

export function usePolygon({ onComplete: onCompleteExternal, filled }: Partial<ControllerOptions> & { filled?: boolean } = {}): Controller {

  const onComplete = useCallback((controller: Controller) => {
    if (onCompleteExternal) {
      onCompleteExternal({
        ...controller,
        closed: true,
        filled
      });
    }
  }, [onCompleteExternal, filled]);

  const {
    points,
    enabled,
    down,
    onPointerDown,
    addPoint,
    addPointWithEvent,
    onPointerLeave,
    onPointerCancel,
    onPointerOut,
    end,
    setDown,
    tool,
    id
  } = useDefaultController({ tool: filled ? POLYGON_FILLED : POLYGON, onComplete, resetOnEnd: true });

  const { scale } = useTransformContext();

  const hasBeenAwayFromStart = useRef<boolean>(false);

  const onPointerUp = useCallback((event: PointerEvent<HTMLElement>) => {
    if (!down) {
      return;
    }

    if (!points[0]) {
      hasBeenAwayFromStart.current = false;
    }

    const added = addPointWithEvent(event, point => !points[0] || !isCloseToStart(points[0], point, scale));
    if (!added && hasBeenAwayFromStart.current) {
      end();
    } else if (added && points[0]) {
      hasBeenAwayFromStart.current = true;
    }

  }, [points, down, addPointWithEvent, end, scale]);

  return useMemo(make, [
    points,
    enabled,
    down,
    onPointerDown,
    onPointerUp,
    addPoint,
    addPointWithEvent,
    onPointerLeave,
    onPointerCancel,
    onPointerOut,
    filled,
    end,
    setDown,
    tool,
    id
  ]);

  function make() {
    return {
      tool,
      points,
      enabled,
      down,
      filled,
      onPointerDown,
      onPointerUp,
      addPoint,
      addPointWithEvent,
      onPointerLeave,
      onPointerCancel,
      onPointerOut,
      end,
      setDown,
      id
    };
  }
}

function isCloseToStart(start: Point, end: Point, zoom: number = 1) {
  const distance = Math.sqrt(
    Math.pow(
      start[0] - end[0],
      2
    ) +
    Math.pow(
      start[1] - end[1],
      2
    )
  );

  return (distance * zoom) < 10;
}
