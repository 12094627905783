import React, {useCallback, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUndo, faRedo} from "@fortawesome/pro-light-svg-icons";
import {useFileContext} from "../../file-context";
import Cheerio from "cheerio";

export function UndoRedo() {
  const { foreground, foregroundSVGCore, updateForeground } = useFileContext();
  const [restoreStack, setRestoreStack] = useState<string[]>([]);
  const $ = useMemo(() => foregroundSVGCore.getCheerio(), [foregroundSVGCore]);
  const elements = useMemo(() => {
    // We actually don't care about the foreground, we just want to know when it changes so
    // we can trigger this refresh of our elements list
    if (!foreground) {
      return [];
    }
    return $("[data-created-at]")
  }, [foreground, $]);
  const onUndo = useCallback(() => {
    const nextElement = elements[elements.length - 1];
    if (!nextElement) {
      return;
    }
    const xml = Cheerio.load(nextElement, { xmlMode: true }).xml();
    setRestoreStack(restoreStack.concat(xml));
    $(nextElement).remove();
    updateForeground(foregroundSVGCore.getSVG());
  }, [setRestoreStack, restoreStack, updateForeground, elements, foregroundSVGCore, $]);
  const onRedo = useCallback(() => {
    const slice = restoreStack.slice(),
      lastIndex = slice.length - 1,
      last = slice[lastIndex];
    if (!last) {
      return;
    }
    slice.splice(lastIndex, 1);
    setRestoreStack(slice);
    const element = $(last);
    element.attr("data-created-at", Date.now().toString());
    $("svg").append(element);
    updateForeground(foregroundSVGCore.getSVG());
  }, [setRestoreStack, restoreStack, foregroundSVGCore, updateForeground, $]);
  return (
    <div className="editor-tool-set">
      <button type="button" onClick={onUndo} title="Undo" disabled={!elements.length}>
        <FontAwesomeIcon icon={faUndo} />
      </button>
      <button type="button" onClick={onRedo} title="Redo" disabled={!restoreStack.length}>
        <FontAwesomeIcon icon={faRedo} />
      </button>
    </div>
  );
}
