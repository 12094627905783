import React, {useMemo} from "react";
import {useFileContext} from "./file-context";

export function EditorBackground() {
    const { matchSizeStyle, background } = useFileContext();

    const backgroundUrl = useMemo(() => {
        if (!background) {
            return undefined;
        }
        return `data:image/svg+xml,${encodeURIComponent(background)}`;
    }, [background]);

    return (
        <div className="editor-background" style={matchSizeStyle}>
            {
                backgroundUrl ? (
                    <img src={backgroundUrl} alt="Background" style={matchSizeStyle} />
                ) : undefined
            }
        </div>
    );
}
