import { Point } from "@markham/svg-control";
import { PointerEvent } from "react";

export function getPoint(event: PointerEvent<HTMLElement>, scale: number, positionX: number, positionY: number, width: number, height: number, windowWidth: number, windowHeight: number): Point {
  const x = event.clientX - positionX;
  const y = event.clientY - positionY;
  const windowScale = getWindowScale();
  return [x / scale / windowScale, y / scale / windowScale];

  function getWindowScale() {
    if (windowWidth >= windowHeight) {
      return windowWidth / width;
    } else {
      return windowHeight / height;
    }
  }
}
