import { getStaticConfig, setStaticConfig } from "@markham/react-services";
import {
  MarkhamInternalApiConfig,
  MarkhamInternalConfig,
  MarkhamStaticConfig
} from "@markham/react-services-static-config";

interface SVGEditorConfig extends MarkhamInternalConfig {
  api: MarkhamInternalApiConfig & {
    pdfSvgUrl: string;
  },
  s3: {
    region: string;
    bucket: string;
  }
}

const staticConfig: MarkhamStaticConfig<SVGEditorConfig> = {
  envConfig: {
    url: {
      'editor.markham.cloud': 'production',
      'dev-editor.markham.cloud': 'development',
      'localhost': 'local',
      'default': 'local'
    }
  },
  env: {
    production: {
      storage: {
        prefix: 'markham.cloud'
      },
      api: {
        jobManagerUrl: 'https://production.api.markham.cloud',
        pdfSvgUrl: "https://production.pdf-svg.api.markham.cloud"
      },
      auth: {
        domain: 'markham.au.auth0.com',
        clientID: 'gH7V6LsXnIsS5qnKvA7xVg1R4QNFY71N',
        audience: 'https://production.pdf-svg.api.markham.cloud/',
        redirectUri: 'https://editor.markham.cloud/callback',
        responseType: 'token id_token',
        scope: 'openid profile email'
      },
      s3: {
        region: "ap-southeast-2",
        bucket: "pdf-svg-service-ap-southeast-2-prod"
      },
      mapBox: {
        token: ''
      },
      google: {
        apiKey: ''
      },
      bugsnag: {
        apiKey: '11117dd2a1214497c643ae338a147bbf'
      }
    },
    development: {
      storage: {
        prefix: 'dev.markham.cloud'
      },
      api: {
        jobManagerUrl: 'https://development.api.markham.cloud',
        pdfSvgUrl: "https://development.pdf-svg.api.markham.cloud"
      },
      s3: {
        region: "ap-southeast-2",
        bucket: "pdf-svg-service-ap-southeast-2-dev"
      },
      auth: {
        domain: 'markham-development.au.auth0.com',
        clientID: '0HJ7BXzk2Rw5Tw6cG6Hla0JGX63Spn6R',
        audience: 'https://development.pdf-svg.api.markham.cloud/',
        redirectUri: 'https://dev-editor.markham.cloud/callback',
        responseType: 'token id_token',
        scope: 'openid profile email'
      },
      mapBox: {
        token: ''
      },
      google: {
        apiKey: ''
      },
      bugsnag: {
        apiKey: '11117dd2a1214497c643ae338a147bbf'
      }
    },
    local: {
      storage: {
        prefix: 'local.markham.cloud'
      },
      api: {
        jobManagerUrl: 'https://development.api.markham.cloud',
        pdfSvgUrl: "https://development.pdf-svg.api.markham.cloud"
      },
      s3: {
        region: "ap-southeast-2",
        bucket: "pdf-svg-service-ap-southeast-2-dev"
      },
      auth: {
        domain: 'markham-development.au.auth0.com',
        clientID: '0HJ7BXzk2Rw5Tw6cG6Hla0JGX63Spn6R',
        audience: 'https://development.pdf-svg.api.markham.cloud/',
        redirectUri: 'http://localhost:3000/callback',
        responseType: 'token id_token',
        scope: 'openid profile email'
      },
      mapBox: {
        token: ''
      },
      google: {
        apiKey: ''
      },
      bugsnag: {
        apiKey: '11117dd2a1214497c643ae338a147bbf'
      }
    }
  }
};

export default setStaticConfig(staticConfig);

export function getPDFSVGUrl() {
  return getStaticConfig<SVGEditorConfig>().api.pdfSvgUrl;
}

export function getS3Config() {
  return getStaticConfig<SVGEditorConfig>().s3;
}
