import { useCallback, useState, useEffect, useMemo, PointerEvent, useRef } from "react";
import { useToolContext } from "../context";
import { ControllerOptions, Controller, usePoints } from "./controller";
import {Point} from "@markham/svg-control";
import {getPoint} from "./point";
import {useTransformContext} from "../../context";
import {useFileContext} from "../../file-context";
import useWindowSize from "react-use-window-size";
import UUID from "uuid";

export function useDefaultController({ tool: type, onComplete, resetOnEnd, content }: ControllerOptions): Controller {
  const [tool] = useToolContext();
  const enabled = tool === type;
  const [points, addPoint] = usePoints();
  const [down, setDown] = useState<boolean>(false);

  const id = useRef<string>("");

  useEffect(() => {
    id.current = UUID.v4();
  }, [enabled]);

  useEffect(() => {

  }, [resetOnEnd, down]);

  const transform = useTransformContext();

  const { width, height } = useFileContext();
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const addPointWithEvent = useCallback((event: PointerEvent<HTMLElement>, valid?: (value: Point) => boolean): boolean => {
    event.stopPropagation();
    const point = getPoint(event, transform.scale, transform.positionX, transform.positionY, width, height, windowWidth, windowHeight);
    const isValid = valid ? valid(point) : true;
    if (isValid) {
      addPoint(point);
    }
    return isValid;
  }, [addPoint, transform, width, height, windowWidth, windowHeight]);


  const onPointerDown = useCallback((event: PointerEvent<HTMLElement>) => {
    event.stopPropagation();
    setDown(true);
  }, [setDown]);

  const end = useCallback((event?: PointerEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setDown(false);
  }, [setDown]);

  useEffect(() => {
    if (!onComplete || down || !points.length) {
      return;
    }
    onComplete(make());
    if (resetOnEnd) {
      // Reset points so that we can start freehand again
      addPoint(undefined);
      id.current = UUID.v4();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [points, down, onComplete, addPoint, resetOnEnd]);

  useEffect(() => {
    if (!enabled) {
      setDown(false);
      addPoint(undefined);
    }
  }, [enabled, setDown, addPoint]);

  return useMemo(make, [down, points, enabled, onPointerDown, end, addPoint, addPointWithEvent, setDown, content, type]);

  function make() {
    return {
      id: id.current,
      points,
      enabled,
      down,
      onPointerDown,
      onPointerUp: end,
      onPointerLeave: undefined, // end,
      onPointerCancel: undefined, // end,
      onPointerOut: undefined, // end,
      addPoint,
      addPointWithEvent,
      end,
      setDown,
      content,
      tool: type
    };
  }
}
