import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHexagon, faArrows, faPencil, faFont, faKeyboard, faCheck } from "@fortawesome/pro-light-svg-icons";
import { faHexagon as faHexagonSolid, faFill } from "@fortawesome/pro-solid-svg-icons";
import {useSetTool, MOVE, useToolContext, useToolResetContext, useToolColorContext} from "../context";
import { FREEHAND, POLYGON_FILLED, POLYGON, TEXT } from "../controllers";
import { ColorPicker, useChangeColor } from "./color-picker";


export function EditorToolsTopRight() {
  const [tool] = useToolContext();
  const onMove = useSetTool(MOVE);
  const onFreehand = useSetTool(FREEHAND);
  const onPolygon = useSetTool(POLYGON);
  const onPolygonFilled = useSetTool(POLYGON_FILLED);
  const onText = useSetTool(TEXT);
  const [, onReset] = useToolResetContext();
  const [color] = useToolColorContext();
  const [changeColor, onChangeColor, onChangeColorComplete] = useChangeColor();
  return (
    <div className="editor-tools editor-tools-top-right">
      <div className="editor-tool-set">
        <button role="option" type="button" title="Move" onClick={onMove} aria-selected={tool === MOVE}>
          <FontAwesomeIcon icon={faArrows} />
        </button>
        <button role="option" type="button" title="Filled polygon" onClick={onPolygonFilled} aria-selected={tool === POLYGON_FILLED}>
          <FontAwesomeIcon icon={faHexagonSolid} />
        </button>
        <button role="option" type="button" title="Polygon" onClick={onPolygon} aria-selected={tool === POLYGON}>
          <FontAwesomeIcon icon={faHexagon} />
        </button>
        <button role="option" type="button" title="Freehand" onClick={onFreehand} aria-selected={tool === FREEHAND}>
          <FontAwesomeIcon icon={faPencil} />
        </button>
        <button role="option" type="button" title="Text" onClick={onText} aria-selected={tool === TEXT}>
          <FontAwesomeIcon icon={faFont} />
        </button>
      </div>
      <br />
      {
        tool === TEXT ? (
          <div className="editor-tool-set">
            <button type="button" title="Re-enter text" onClick={onReset}>
              <FontAwesomeIcon icon={faKeyboard} />
            </button>
            <button type="button" title="Save text" onClick={onMove}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        ) : undefined
      }
      <br />
      <div className="editor-tool-set">
        <button type="button" title="Change color" onClick={onChangeColor}>
          <FontAwesomeIcon icon={faFill} color={color} />
        </button>
      </div>
      <ColorPicker enabled={changeColor} onComplete={onChangeColorComplete} />
    </div>
  );
}
