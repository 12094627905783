import React, {useCallback} from "react";
import { useTransformContext } from "../../context";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSearchMinus, faSearch } from "@fortawesome/pro-light-svg-icons";
import { UndoRedo } from "./undo-redo";

export function EditorToolsTopLeft() {
  const { zoomIn, zoomOut, resetTransform, setScale } = useTransformContext();
  const reset = useCallback(() => {
    resetTransform();
    setScale(1.0001);
  }, [setScale, resetTransform]);
  return (
    <div className="editor-tools editor-tools-top-left">
      <div className="editor-tool-set">
        <button type="button" onClick={zoomIn} title="Zoom In">
          <FontAwesomeIcon icon={faSearchPlus} />
        </button>
        <button type="button" onClick={zoomOut} title="Zoom Out">
          <FontAwesomeIcon icon={faSearchMinus} />
        </button>
        <button type="button" onClick={reset} title="Reset">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
      <br />
      <UndoRedo />
    </div>
  );
}
