import {PointerEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Controller, ControllerOptions} from "./controller";
import {useDefaultController} from "./default";
import {MOVE, useToolContext, useToolResetContext} from "../context";

export const TEXT = "TEXT";

export function useText({ onComplete }: Partial<ControllerOptions> = {}): Controller {


  const [text, setText] = useState<string>("");
  const [reset] = useToolResetContext();
  const {
    points,
    enabled,
    down,
    addPoint,
    addPointWithEvent,
    onPointerDown,
    onPointerLeave,
    onPointerCancel,
    onPointerOut,
    end,
    setDown,
    tool,
    id
  } = useDefaultController({ tool: TEXT, onComplete, content: text });

  const [, setTool] = useToolContext();

  useEffect(() => {
    if (reset) {
      setText("");
    }
  }, [reset, setText]);

  useEffect(() => {
    if (enabled && points.length === 0) {
      addPoint([100, 100]);
    }
  }, [enabled, addPoint, points]);

  useEffect(() => {
    if (enabled && !text) {
      const givenText = prompt("Please enter the text you would like to add");
      if (givenText) {
        setText(givenText);
      } else {
        setTool(MOVE);
      }
    } else if (!enabled && text) {
      setText("");
    }
  }, [enabled, setText, text, setTool]);

  const onPointerMove = useCallback((event: PointerEvent<HTMLElement>) => {
    if (!down) {
      return;
    }
    addPointWithEvent(event);
  }, [down, addPointWithEvent]);

  const onPointerUp = useCallback((event: PointerEvent<HTMLElement>) => {
    if (!down) {
      return;
    }
    addPointWithEvent(event);
    setDown(false);
  }, [down, addPointWithEvent, setDown]);

  return useMemo(make, [
    points,
    enabled,
    down,
    onPointerDown,
    onPointerUp,
    addPoint,
    addPointWithEvent,
    onPointerLeave,
    onPointerCancel,
    onPointerOut,
    end,
    text,
    onPointerMove,
    setDown,
    tool,
    id
  ]);

  function make() {
    return {
      points,
      enabled,
      down,
      onPointerDown,
      onPointerUp,
      addPoint,
      addPointWithEvent,
      onPointerLeave,
      onPointerCancel,
      onPointerOut,
      end,
      content: text,
      onPointerMove,
      setDown,
      tool,
      id
    };
  }
}
