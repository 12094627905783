import React, {Fragment} from "react";
import { useFileContext } from "./file-context";

export interface EditorForegroundProps {
    foreground?: string;
}

export function EditorForeground(props: EditorForegroundProps) {
  const { matchSizeStyle, foreground } = useFileContext();

  if (!(foreground || props.foreground)) {
    return <Fragment />;
  }

  return (
    <div className="editor-foreground-persisted" style={matchSizeStyle}>
      <img src={`data:image/svg+xml,${encodeURIComponent(props.foreground || foreground)}`} alt="Persisted Editing Surface" style={matchSizeStyle} />
    </div>
  )
}

/*

  useEffect(() => {

    const newPath = path();

    newPath.addPoint(100, 100);
    newPath.addPoint(100, 200);
    newPath.addPoint(200, 200);
    newPath.addPoint(200, 100);
    newPath.close();

    const secondPath = path();

    secondPath.addPoint(150, 150);
    secondPath.addPoint(150, 300);
    secondPath.addPoint(300, 300);
    secondPath.addPoint(300, 150);
    secondPath.close();

    newPath.setAttribute("stroke", "blue");
    newPath.setAttribute("fill", "green");
    newPath.setAttribute("fill-opacity", "0.5");

    secondPath.setAttribute("stroke", "green");
    secondPath.setAttribute("fill", "blue");
    secondPath.setAttribute("fill-opacity", "0.5");

    const $ = workingSvg.getCheerio();
    $("svg").append(secondPath.toString(), newPath.toString());

    const image = workingSvg.getSVG();
    setWorkingDataUri(
      `data:image/svg+xml;utf8,${image}`
    );

  }, [setWorkingDataUri, workingSvg]);
 */
