import React, {createContext, ReactNode, useCallback, useContext, useMemo, useState} from "react";
import { FREEHAND, POLYGON, POLYGON_FILLED, TEXT } from "./controllers";
import UUID from "uuid";

export const MOVE = "MOVE";

export type Tool = typeof FREEHAND | typeof MOVE | typeof POLYGON | typeof POLYGON_FILLED | typeof TEXT;

export const ToolContext = createContext<[Tool, (value: Tool) => void]>(["MOVE", () => {}]);
export const ToolResetContext = createContext<[unknown, () => void]>([undefined, () => {}]);
export const ToolColorContext = createContext<[string, (value: string) => void]>(["#d63f95", () => {}]);

export interface ToolProviderProps {
  children: ReactNode;
  initialTool?: Tool;
}

export function ToolProvider({ children, initialTool }: ToolProviderProps) {
  const [tool, setTool] = useState<Tool>(initialTool || "MOVE");
  const value = useMemo((): [Tool, (value: Tool) => void] => [tool, setTool], [tool, setTool]);
  return (
    <ToolContext.Provider value={value}>
      {children}
    </ToolContext.Provider>
  )
}

export function useToolContext(): [Tool, (value: Tool) => void] {
  const value = useContext(ToolContext);
  if (!value) {
    throw new Error("Expected ToolContext to be provided");
  }
  return value;
}

export function useSetTool(tool: Tool): () => void {
  const [, set] = useToolContext();
  return useCallback(() => {
    set(tool);
  }, [tool, set]);
}

export interface ToolResetProviderProps {
  children: ReactNode;
}

export function ToolResetProvider({ children }: ToolResetProviderProps) {
  const [reset, setReset] = useState<unknown>(undefined);
  const onReset = useCallback(() => {
    setReset(UUID.v4());
  }, [setReset]);
  const value = useMemo((): [unknown, () => void] => [reset, onReset], [reset, onReset]);
  return (
    <ToolResetContext.Provider value={value}>
      {children}
    </ToolResetContext.Provider>
  );
}

export function useToolResetContext() {
  return useContext(ToolResetContext);
}

export interface ToolColorProviderProps {
  children: ReactNode;
}

export function ToolColorProvider({ children }: ToolColorProviderProps) {
  const [color, setColor] = useState<string>("#d63f95");
  const onColor = useCallback((value: string) => {
    setColor(value);
  }, [setColor]);
  const value = useMemo((): [string, (value: string) => void] => [color, onColor], [color, onColor]);
  return (
    <ToolColorContext.Provider value={value}>
      {children}
    </ToolColorContext.Provider>
  );
}

export function useToolColorContext() {
  return useContext(ToolColorContext);
}
