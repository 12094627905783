import "./static-config";
import "./bugsnag";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ParentComponent } from "@markham/react-services";
import { Loading } from "./editor/loading";

ReactDOM.render(
    (
        <ParentComponent progressIndicator={<Loading />}>
            {
                () => <App />
            }
        </ParentComponent>
    ),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
