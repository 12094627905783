import { PointerEvent, useReducer, useMemo } from "react";
import { Point } from "@markham/svg-control";
import { Tool } from "../context";

export interface ControllerCallbacks {
  onPointerDownCapture?(event: PointerEvent<HTMLElement>): void;
  onPointerMoveCapture?(event: PointerEvent<HTMLElement>): void;
  onPointerUpCapture?(event: PointerEvent<HTMLElement>): void;
  onPointerCancelCapture?(event: PointerEvent<HTMLElement>): void;
  onPointerOutCapture?(event: PointerEvent<HTMLElement>): void;

  onPointerDown?(event: PointerEvent<HTMLElement>): void;
  onPointerMove?(event: PointerEvent<HTMLElement>): void;
  onPointerUp?(event: PointerEvent<HTMLElement>): void;
  onPointerCancel?(event: PointerEvent<HTMLElement>): void;
  onPointerLeave?(event: PointerEvent<HTMLElement>): void;
  onPointerOut?(event: PointerEvent<HTMLElement>): void;
}

export interface Controller extends ControllerCallbacks {
  id: string;
  tool: Tool;
  points: Point[];
  addPoint(point: Point): void;
  addPointWithEvent(event: PointerEvent<HTMLElement>, valid?: (point: Point) => boolean): boolean;
  end(event?: PointerEvent<HTMLElement>): void;
  setDown(down: boolean): void;
  down?: boolean;
  filled?: boolean;
  closed?: boolean;
  enabled?: boolean;
  content?: string;
}

export interface EnabledController extends Controller {
  enabled: true;
}

export interface ControllerOptions {
  tool: Tool;
  onComplete?(controller: Controller): void;
  resetOnEnd?: boolean;
  content?: string;
}

export function usePoints() {
  return useReducer((state: Point[], next?: Point) => {
    if (next) {
      return state.concat([next]);
    } else {
      return [];
    }
  }, []);
}

export function isEnabledController(controller: Controller): controller is EnabledController {
  return controller.enabled === true;
}

export function useEnabledController(...controllers: Controller[]): EnabledController | undefined {
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  return useMemo(() => controllers.find(isEnabledController), controllers);
}

export function useControllerCallbacks(controller?: Controller): ControllerCallbacks | undefined {
  return useMemo(() => {
    if (!controller) {
      return undefined;
    }
    return {
      onPointerDownCapture: controller.onPointerDownCapture || controller.onPointerDown,
      onPointerMoveCapture: controller.onPointerMoveCapture || controller.onPointerMove,
      onPointerUpCapture: controller.onPointerUpCapture || controller.onPointerUp,
      onPointerCancelCapture: controller.onPointerCancelCapture || controller.onPointerCancel,
      onPointerLeave: controller.onPointerLeave,
      onPointerOutCapture: controller.onPointerOutCapture || controller.onPointerOut,
    };
  }, [controller]);
}
