import React from "react";
import {useFileContext} from "../../file-context";

export function EditorToolsBottomRight() {

  const { triggerDownload } = useFileContext();

  return (
    <div className="editor-tools editor-tools-bottom-right">
      {
        window.isMarkhamEmbedded ? undefined : (
          <button type="button" className="download-button" onClick={triggerDownload}>
            Download
          </button>
        )
      }
    </div>
  );
}
