import React, {Fragment, ReactNode} from "react";
import useAsync from "react-use/esm/useAsync";

export interface PointerEventsLoaderProps {
  children: ReactNode
}

export function PointerEventsLoader(props: PointerEventsLoaderProps) {
  const pointerEventsLoaded = useAsync(async () => {
    if (!("PointerEvent" in window)) {
      await import("@wessberg/pointer-events");
    }
    return true;
  }, []);

  if (!pointerEventsLoaded.value) {
    return <Fragment />;
  }

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
}
