import { useMemo } from "react";
import { ControllerOptions, Controller} from "./controller";
import { useDefaultController } from "./default";

export const FREEHAND = "FREEHAND";

export function useFreehand({ onComplete }: Partial<ControllerOptions> = {}): Controller {
  const {
    points,
    enabled,
    down,
    onPointerDown,
    onPointerUp,
    addPoint,
    addPointWithEvent,
    onPointerLeave,
    onPointerCancel,
    onPointerOut,
    end,
    setDown,
    tool,
    id
  } = useDefaultController({ tool: FREEHAND, onComplete, resetOnEnd: true });

  return useMemo(make, [
    points,
    enabled,
    down,
    onPointerDown,
    onPointerUp,
    addPoint,
    addPointWithEvent,
    onPointerLeave,
    onPointerCancel,
    onPointerOut,
    end,
    setDown,
    tool,
    id
  ]);

  function make() {
    return {
      points,
      enabled,
      down,
      onPointerDown,
      onPointerUp,
      addPoint,
      addPointWithEvent,
      onPointerLeave,
      onPointerCancel,
      onPointerOut,
      onPointerMove: addPointWithEvent,
      end,
      setDown,
      tool,
      id
    };
  }
}
