import React, { Fragment } from "react";
import { EditorToolsTopLeft } from "./top-left";
import { EditorToolsTopRight } from "./top-right";
import { EditorToolsBottomLeft } from "./bottom-left";
import { EditorToolsBottomRight } from "./bottom-right";
import "../index.css";

export function EditorTools() {
    return (
        <Fragment>
            <EditorToolsTopLeft />
            <EditorToolsTopRight />
            <EditorToolsBottomLeft />
            <EditorToolsBottomRight />
        </Fragment>
    );
}
