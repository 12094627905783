import { createContext, useContext } from "react";
import { TransformWrapperInstance } from "react-zoom-pan-pinch";

export const TransformContext = createContext<TransformWrapperInstance | undefined>(undefined);

export const { Provider: TransformProvider } = TransformContext;

export function useTransformContext(): TransformWrapperInstance {
  const value = useContext(TransformContext);
  if (!value) {
    throw new Error("Expected TransformContext to be provided");
  }
  return value;
}
