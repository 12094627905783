import React from 'react';
import './index.css';
import { TransformWrapper, TransformComponent, TransformWrapperInstance } from "react-zoom-pan-pinch";
import { EditorSurface } from "./editor-surface";
import { EditorTools } from "./tools/controls";
import { FileProvider } from "./file-context";
import { PointerEventsLoader } from "./pointer-events";
import { TransformProvider } from "./context";
import { ToolResetProvider, ToolProvider, useToolContext, ToolColorProvider } from "./tools/context";

function EditorContents() {
    const [tool] = useToolContext();
    return (
        <TransformWrapper
          enableTouchPadPinch={tool === "MOVE"}
          enableWheel={tool === "MOVE"}
          pinchEnabled={tool === "MOVE"}
          panningEnabled={tool === "MOVE"}
          dbClickEnabled={tool === "MOVE"}
          transformEnabled={tool === "MOVE"}
        >
            {
                (transformReference: TransformWrapperInstance) => (
                    <TransformProvider value={transformReference}>
                        <TransformComponent>
                            <EditorSurface />
                        </TransformComponent>
                        <EditorTools />
                    </TransformProvider>
                )
            }
        </TransformWrapper>
    )
}

export function Editor() {
  return (
    <ToolColorProvider>
      <ToolResetProvider>
        <ToolProvider>
          <PointerEventsLoader>
            <FileProvider>
                <div className="editor">
                    <EditorContents />
                </div>
            </FileProvider>
          </PointerEventsLoader>
        </ToolProvider>
      </ToolResetProvider>
    </ToolColorProvider>
  );
}

function preventDefault(event: Event) {
  if (event.preventDefault) {
    event.preventDefault();
  }
  event.returnValue = false;
}

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys: Record<number, number> = {37: 1, 38: 1, 39: 1, 40: 1};
function preventDefaultForScrollKeys(event: KeyboardEvent) {
  if (keys[event.keyCode]) {
    preventDefault(event);
    return false;
  }
}

function disableScroll() {
  if (window.addEventListener) {
    window.addEventListener("DOMMouseScroll", preventDefault, false);
  }
  document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove  = preventDefault; // mobile
  document.body.onwheel = preventDefault; // modern standard
  document.body.ontouchmove  = preventDefault; // mobile
  document.onkeydown  = preventDefaultForScrollKeys;
}

disableScroll();

